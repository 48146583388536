{
  "name": "@ringcentral/sdk",
  "version": "4.7.4",
  "scripts": {
    "clean": "rimraf dist/* lib/* es6/* coverage/* .nyc_output .rpt2_cache",
    "test": "npm run nyc && npm run karma",
    "test:quick": "npm run mocha",
    "test:watch": "npm run test:quick -- --watch",
    "mocha": "mocha --opts mocha.opts",
    "karma": "karma start karma.conf.js",
    "nyc": "nyc mocha --opts mocha.opts",
    "build": "npm run clean && npm run build:tsc:es5 && npm run build:tsc:es6 && npm run build:webpack",
    "build:tsc:es5": "tsc",
    "build:tsc:es6": "tsc --project tsconfig.es6.json",
    "build:webpack": "webpack --progress",
    "start": "npm-run-all -p start:tsc:es5 start:tsc:es6 start:webpack",
    "start:tsc:es5": "npm run build:tsc:es5 -- --watch --preserveWatchOutput",
    "start:tsc:es6": "npm run build:tsc:es6 -- --watch --preserveWatchOutput",
    "start:webpack": "npm run build:webpack -- --watch"
  },
  "dependencies": {
    "dom-storage": "^2.1.0",
    "is-plain-object": "^2.0.4",
    "node-fetch": "^2.6.7"
  },
  "devDependencies": {
    "@ringcentral/sdk-utils": "^4.7.4",
    "@types/mocha": "^5.2.5",
    "@types/node": "^18.8.5",
    "karma": "^6.3.16",
    "mocha": "^5.2.0",
    "npm-run-all": "^4.1.3",
    "nyc": "^13.1.0",
    "rimraf": "^2.6.2",
    "source-map-support": "^0.5.9",
    "ts-loader": "^8.4.0",
    "ts-node": "^10.9.1",
    "tslib": "^2.4.0",
    "typescript": "^4.8.4",
    "webpack": "^4.23.0",
    "webpack-cli": "^3.1.2"
  },
  "main": "./lib/index.js",
  "module": "./es6/index.js",
  "types": "./lib/SDK.d.ts",
  "browser": "./lib/SDK.js",
  "author": {
    "name": "RingCentral, Inc.",
    "url": "https://developers.ringcentral.com"
  },
  "contributors": [
    {
      "name": "Kirill Konshin"
    }
  ],
  "repository": {
    "type": "git",
    "url": "git://github.com/ringcentral/ringcentral-js.git"
  },
  "bugs": {
    "url": "https://github.com/ringcentral/ringcentral-js/issues"
  },
  "homepage": "https://github.com/ringcentral/ringcentral-js",
  "engines": {
    "node": ">=4"
  },
  "license": "MIT",
  "publishConfig": {
    "access": "public"
  },
  "gitHead": "25223f1b52f4e1ae98e2c2f8633a972ddf1c07b4"
}
